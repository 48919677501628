import './lightbox.js';

window.onload = () => {
    const burger = document.getElementById("burger");
    if (screen.width < 768) {
        burger.classList = "plate plate7";
    }
  };

//Menu Mobile
document.querySelector("div.plate").addEventListener('click', () => {
    const menu = document.getElementById("menu");
    const main = document.getElementById("main");
    const body = document.querySelector("body")

    if (menu.className === "nav-menu") {
        menu.className = "nav-menu-open";
        main.className = "main main-menu-open";
        body.className = "body-menu-open";
    } else {
        menu.className = "nav-menu";
        main.className = "main";
        body.className = "";
    }
});

//Logo-bg
const bg = document.getElementById("logo-bg");

function changeImg(){
    screen.width >= 1300 ? bg.src = "/images/languette.svg" : bg.src = "/images/languette-min.svg";
}

window.onload = changeImg;
window.onresize = changeImg;